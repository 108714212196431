.card-option{
  padding: 0 15px;
  &:last-of-type{
    .form-group {
      border-bottom: 1px solid $border-color;
    }
  }

  .form-group {
    border-top: 1px solid $border-color;
    min-height: 50px;
    width: 100%;
    padding: 5px 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  .circle{
    top:50%!important;
    transform: translateY(-50%);
  }
  .img-card{
    max-width: 60px;
    display: block;
    margin-left: auto;
  }
  .form-check {
    padding-left: 1px;
  }
}

.save-card {
  .form-group {
    margin-left: -2px;
  }
  .cerror {
    margin-left: 2px;
  }
}