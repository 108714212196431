.three-steps{
    .step-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 18px;
        p{
            text-align: center;
        }
        img{
            margin-bottom: 20px;
            max-height: 200px;
        }
    }
}

@media screen and (min-width:992px){
    .three-steps {
        .step-wrapper{
           padding: 0 20px;
            font-size: 25px;
            line-height: 35px;
            img{
               max-height: 150px;
            }
        }
    }
}
