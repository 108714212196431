.wrapper-club-elements{
    margin: 20px 0; 
    .react-multi-carousel-item{
        padding: 10px;
        .card{
            min-height: 250px;
            display: flex;
            position:relative;
            background-size: cover!important; 
            overflow: hidden;
            &::before{
                display: block;
                position: absolute;
                z-index: 2;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0,0,0,.3);
                content: '';
            }
            img{
                opacity: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: auto;
                z-index: 0;
            }
            .title{
                position: relative;
                z-index: 3;
                color:white;
                margin: auto 0 0 0;
                padding: 0 10px;
                font-weight: 400;
                font-size: 15px;
                color:$white;
            }
            .star-ratings{
                padding: 0 10px 10px 10px;
                z-index: 3;
                position: inherit;
            }
        }
    }
    .react-multiple-carousel__arrow{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .react-multiple-carousel__arrow--right{
        right: 0;
    }
    .react-multiple-carousel__arrow--left{
        left: 0;
    }
}