.sidebar-wrapper{
  padding-top: 0;

  .search-button {
    display: none;
  }

  .nav{
    margin-top: 0;

    .logout {
      color: $red;
       i {
        color: $red;
      }
    }
  }
  .nav-mobile-menu{
    .nav-item{
      .dropdown-item{
        margin:0 0 0 60px;
        padding-left: 0;
      }
    }
  }


}
