.app-section{
    color: $green-400;
    padding: 0;
    margin-bottom: 100px;
    h2{
        font-size: 30px;
        font-weight: 600;
    }
    .app-description{
        display: flex;
        height: 100%;
        flex-direction: column;
        ul{
            padding: 0;
            list-style-position: inside;
        }
    }
    .reviews-app{
        margin: 40px 0;
        display: inline-block;
        width:auto;
        p{
            margin:0 0 5px 0;
        }
    }
    .wrapper-applogos{
        margin-bottom: 50px;
        flex-direction: row;
        margin-top: 20px;
        justify-content: flex-start;
        a{
            &:first-child{
                margin-right: 10px;
            }
            img{
                max-width: 150px;
                width: 100%;
                display: inline-block;
            }
        }
    }
    .app-image{
        img{
            max-height: 300px;
        }
    }
}

@media screen and (min-width:992px){
    .app-section{
        margin: 0;
        padding: 0 0 100px 0;
        h2{
            font-size: 40px;
            text-align: left;
        }
        .reviews-app{
            max-width: 70%;
            margin: 30px 0;
        }
        .wrapper-applogos{
            margin-top:40px;
           margin-bottom: 0;
           max-width: 70%;
           a{
               &:first-child{
                   margin-right: 20px;
               }
               img{
                   max-width: 200px;
               }
           }
        }
        .app-description{
            justify-content: center;
            font-size: 20px;
                line-height: 30px;
            ul{
                color:black;
            }
        }
        .app-image{
            img{
                max-height: none;
            }
        }
    }
}
