.with-day{
  border: none;
  background: #12803c;
  color: #fff;
  text-align: center;
  padding: 5px;
  margin: 0 0 0 auto;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 45px;
  .custom-input{
    margin:0;
    height: 100%;
    font-weight: 400;
  }
}
