.page-header{
  display: flex;
  align-items: center;
  justify-content: center;
  .brand{
    text-align: center;
    h1{
      font-size: 25px;
      line-height: 30px;
    }
  }
  .card{
    .form-control:disabled, .form-control[readonly] {
      background-color: $white;
      opacity: 1;
    }
  }
  .wrapper-pannel-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    .react-datepicker-wrapper{
      width:100%;
      input{
        max-width: 100%;
        width: 100%;
        height: 38px;
        border-radius: 5px;
        box-shadow: none;
        border: 1px solid #cccccc;
        padding-left: 10px;
        color:#333333;
        opacity:.7;
        &::placeholder{
          color:#333333;
          opacity:1;
        }
      }
    }
  }
}

.datepicker{
  border:none!important;
  background: $white;
  border-radius: 5px!important;
  font-family: $font-family-sans-serif;
  font-size: 14px;
  .react-datepicker__header{
    background: $white;
    border-bottom: none;
  }
  .react-datepicker__today-button{
    background: $white;
    background: $green-400;
    border-top: none;
    color: $white;
    font-weight: 400;

  }
  .react-datepicker__day--keyboard-selected{
    background: $green-400;
    color: $white;
  }
  .react-datepicker__day--selected{
    background: $green-400!important;
  }
}

.react-datepicker-wrapper{
  input{
    max-width: 100%;
    width:100%;
    min-height: 38px;
    border: 1px solid hsl(0,0%,80%);
    border-radius: 5px;
    padding-left: 10px;
    color:#333333;
  }
}


.home-slider {
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .background-slider {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .background-slide {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
}

.promo-clubs {
  .slick-list{padding:0 20% 0 0;}

  .slick-dots {
    li {
      button {
        &:before {
          font-size: 12px;
          color: #12803c;
        }
      }

      &.slick-active {
        &:before {
          color: transparent;
          border: 1px solid #12803c;
        }
      }
    }
  }
}


@media screen and (min-height: 400px){
  .page-header{
    .wrapper-pannel-content{
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: 991px){
  .page-header{
   .card-signup{
     margin-top: 100px;
   }
  }
}



@media screen and (min-width: 768px){
    .home-panel{
      height: 70vh;
      min-height: 800px;
      display: flex;
      align-items: center;
      text-align: center;
      .brand{
        h1{
          font-size: 48px;
          line-height: 65px;
        }
      }
    }
  }

@media screen and (min-width: 1200px){
  .navbar{
    .container{
      width:100vw;
      max-width:100%;
      padding: 0 150px;
    }
  }
}



