.static-text{
  padding: 30px 0 0 0;

  .cpt{
    padding-top: 30px;
  }
  .intro{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  ul{
    padding: 0;
    list-style-position: inside;
  }
  .c-section{
    .nosp{
      display: none;
    }
  }
}

.payment{
  display: flex;
  height:100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  h1{
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px){
  .payment{
    h1{
      font-size: 40px;
    }
  }
}

@media screen and (min-width: 1200px){
  .static-text{
    .container-fluid{
      padding: 0 150px;
    }
    .cpt{
      padding-top: 30px;
      h2{
        margin: 0;
        max-width: 80%;
      }
    }
    .cols-2{
      column-count: 2;
    }
    .c-section{
      padding-bottom: 70px;
      .nosp{
        display: block;
      }
    }
  }
}
