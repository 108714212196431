.media-section{
    h3{
        font-size: 22px;
        color: $green-400;
    }
    .logo{
        margin-bottom: 20px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width:992px){
    .media-section{
        padding: 0 0 180px 0;
        h3{
            font-size: 40px;
            line-height: 50px;
            margin-bottom: 20px;
        }
        .logo{
            img{
                max-height: 140px;
            }
        }
    }
}