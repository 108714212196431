.wrapper-table-data {
  overflow: hidden;
  .arrows{
    display: flex;
    justify-content: flex-end;
  }

  .cell{
    width:100%;
    height: 5rem;
    border: 1px solid $white;
    border-bottom: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: relative;
    color: $white;
    padding: 0;
    border-radius: 5px;
    &:hover{
      cursor: pointer;
    }
    .wrapper-td{
      display: flex;
      flex-direction: row;
      font-size: 13px;
      padding: 0 5px;
      z-index: 3;
      height: 50%;
      margin: 0;
      align-items: center;
      .c-time{
        color: $green-400;
      }
      .day{
        margin-left: auto;
        color: $red-400;
      }
    }
    .values{
      font-size: 14px;
      width: 100%;
      position: relative;
      margin-top: 0.1rem;
      span{
        width: 2rem;
        margin-right: .2rem;
        display: inline-block;
        background: black;
        &.free{
          background: $green-400;
        }
        &.partial{
          background: $orange-300;
        }
        &.ocupied{
          background: $red-400;
        }
      }
      &.w-multiple{
        position: absolute;
        margin: 0;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        span{
          display: flex;
          background: none;
          padding-top: 0;
          height: 50%;
          border-bottom: 1px solid rgba(255,255,255,.2);
          width: 100%;
          align-items: center;
          justify-content: center;
          margin-right: 0;
          &:last-child{
            border-bottom: none;
          }
        }
      }
    }
    &.libereall{
      background-color: white;
      color: black;
      border-color: $grey;
      .values{
        font-size: 14px;
        margin-top: -10px;
      }
    }
    &.part-rez{
      background-color: $orange-300;
      color: black;
    }
    &.libere0{
      background-color: $red-600;
      border-color: $grey;
      .ocupat.full{
        height: 100%;
      }
      .w-multiple{
        span{
          &:first-child{
            flex-grow: 1;
          }
        }
      }
      .values{
        font-size: 14px;
        margin-top: 0;
      }
      .c-time{
        color: white;
      }
      .day{
        color:white;
      }
    }
    &.liberesome{
      background: $yellow-a200;
      color:$black;
      border-color: grey;
      &.teren{
        background: transparent;
        .wrapper-td{
          .c-time,.day{
            color: $white!important;
          }
        }
      }
    }
    &.past{
      opacity: .5;
    }
    &:last-child{
      border-bottom: 1px solid grey;
    }

    .wrapper-full, .wrapper-half{
      height:100%;
      display:flex;
      padding: 0 5px;
      align-items: center;
      .note{
        margin: 0 auto;
      }
      .c-time,.day{
        font-size: 10px;
      }
    }

  }
  .time{
    justify-content: center;
    color: $black;
    background-color: $teal-100;
    font-size: 14px;
    &:last-child{
      border-bottom: hidden;
    }
  }
  .head-cell{
    color: $green-400;
    border: none;
    align-items: center;
    justify-content: center;
    &.blocked{
      color: $red;
      font-weight: bold;
    }
  }

}

@media screen and (max-width: 767px){
  .wrapper-table-data{

    .slick-slide{
      width: 15rem!important;
    }
  }
}

@media screen and (min-width: 1200px){
  .wrapper-table-data{
    padding-right: 7px!important;
    margin-top: 2rem;
    .arrows{
      display: none;
    }
    .cell{
      .values{
        margin-top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
          width: 2rem;
          margin-right: .2rem;
          display: inline-block;
          background: black;
          &.free{
            background: $green-400;
          }
          &.partial{
            background: $orange-300;
          }
          &.ocupied{
            background: $red-400;
          }
        }
      }
    }
  }
}