.search-overlay{
    position:fixed;
    width:100vw;
    height:100vh;
    top:-150vh;
    left:0;
    background: rgba(0,0,0,0.8);
    z-index: -1;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 20px;
    flex-direction:column;
    color:white;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); transition-duration: 0.5s;
    &.active{
        top:0;
        transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); transition-duration: 0.5s;
        z-index: 10000;
    }

    .wrapper-search{
        max-width: 100%;
        display: flex;
        align-items: center;
        background-color: $white;
        padding: 20px;
        border-radius: 10px;
        .btn-search{
            background: $green-400;
            height:100%;
            margin-left: 10px;
            min-width: 70px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            &:hover{
                cursor:pointer;
            }
        }
    }
    .close{
        color:white;
        position: absolute;
        top:20px;
        right: 40px;
    }
}

.wrapper-search-button{
    display: flex;
    align-items: flex-start;
    &:hover{
        cursor: pointer;
    }
}

.clubs{
    .wrapper-search-button{
        svg{
            color: black!important;
        }
    }
}

@media screen and (min-width:992px){
    .wrapper-search-button{
        display: flex;
        margin-top: 2px;
    }
}