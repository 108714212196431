/* Base styles for the footer */
.footer {
  padding: 50px 10px;
  display: block;
  text-align: left;
  background: $white;
  font-weight: normal;
  margin: 100px 0 0;
  border-top: 1px solid #12803c;
}

.footer ul {
  list-style: none;
  padding: 0;
}

.footer ul li {
  display: block;
  padding: 0;
}

.footer ul li a {
  padding: 0;
  text-align: left;
  font-size: 15px;
  line-height: 30px;
  text-transform: none;
  font-weight: normal;
  text-decoration: none;
}

.footer ul li a:hover {
  color: #12803c;
}

.footer .netopia {
  text-align: left;
}

.footer .netopia img {
  max-width: 150px;
  margin-bottom: 5px;
}

.footer .second {
  border-top: 1px solid $border-color;
  margin-top: 50px;
  padding: 20px 0;
}

.footer .copy p {
  display: flex;
  align-items: center;
}

.footer .copy img {
  max-width: 200px;
}

.footer .CookieConsent {
  align-items: center !important;
  font-size: 12px;
  line-height: 14px;
}

.footer .anpc a:first-child {
  display: inline-block;
}

.footer .anpc img {
  display: inline-block;
  max-width: 150px;
  margin-bottom: 5px;
}

.footer .footer-secondary {
  margin-top: 30px;
}

.footer .footer-secondary .social-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .footer-secondary .social-links a {
  margin: 0 10px;
}

.loading-panel-full-window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.app-banner {
  font-size: 14px;
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 10px;
  background-color: rgb(43, 55, 59);
  color: white;
  line-height: 14px;
  z-index: 100000;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: .3s;
}

.app-banner svg:hover {
  cursor: pointer;
}

.app-banner.open {
  top: 0;
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-duration: .3s;
}

.app-banner p {
  margin: 0;
}

.app-banner .logo-app {
  width: 30px;
  margin: 0 10px;
}

.app-banner .app-description .title-app {
  font-weight: bold;
}

.app-banner button {
  border: none;
  background-color: $green-400;
  color: white;
  padding: 8px 10px;
  border-radius: 3px;
  margin-left: auto;
  text-transform: uppercase;
}

.app-banner button:hover {
  cursor: pointer;
}

/* Additional styles for different screen sizes */
@media screen and (min-width: 768px) {
  .footer .netopia {
    margin-top: 0;
  }
}

@media screen and (min-width: 992px) {
  .footer .CookieConsent {
    align-items: center !important;
  }
  .footer .netopia {
    text-align: right;
  }
}

