.navigation-deskt {
  display: flex;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  z-index: 1000;
  width: 100%;
  max-width: 100vw;
  padding: 10px 0;
  transition: transform 0.3s ease, background-color 0.3s ease;

  .nav-wrapper {
    padding: 0 15px;
  }

  &.slide-up {
    transform: translateY(-100%);
  }

  &.slide-down {
    transform: translateY(0);
  }

  &.scrolled {
    background-color: #ffffff; /* Adjust as needed */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add box shadow */
    color: #000000;

    .navbar-toggler {

      .navbar-toggler-icon {
        background: #000000;
      }
    }

    .nav {
      .nav-link {
        color: #000000;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .nav-link {
    color: #fff;

    &:hover{
      color: #12803c!important;
      cursor: pointer;
    }
  }

  img {
    max-width: 180px;
  }

  .nav {
    display: none;
  }

  .navbar-toggler {
    cursor: pointer;
    outline: 0;

    .navbar-toggler-icon {
      width: 22px;
      height: 2px;
      vertical-align: middle;
      outline: 0;
      display: block;
      border-radius: 1px;
      color: $white;
      background: $white;

      & + .navbar-toggler-icon {
        margin-top: 4px;
      }
    }
  }
}

.social-links {
  display: none;

  &.mobile-links {
    display: flex;
    margin-left: auto;
    align-items: center;

    img {
      width: 22px;
    }
  }
}

.nav-mobile {
  &.active {
    transform: translate3d(0, 0, 0);
  }
}

.navbar {
  .navbar-brand {
    color: $black;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1031;
  background: black;
  opacity: 0.4;

  &.hidden {
    display: none;
  }
}

.clubs, .club {
  background: $white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add box shadow */

  .navbar-toggler {
    span {
      background-color: $black !important;
    }
  }
}

.filters {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  z-index: 1000;
  left: -100%;
  background: white;
  transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);

  .close {
    display: block;
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 11;
  }

  .card {
    position: relative;
  }

  .card-body {
    padding: 10vh 30px !important;
  }

  &.active {
    left: 0;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
  }

  .filters-wrapper {
    padding: 50px 10px;

    @media screen and (min-width: 991px){
      padding: 0;
    }
  }
}

.sidebar-wrapper {
  padding-top: 20px;

  .nav-link {
    &.user-nav {
      margin-bottom: 20px !important;
      background: #12803c;
      justify-content: center;

      span {
        display: flex;
        justify-content: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
        line-height: 20px;
      }
    }

    &.logout {
      text-transform: capitalize;

      &:hover {
        color: #f44336;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.filters-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #D3D3D3;
  padding: 15px 15px 0 15px;
  margin-top: 15px;

  @media screen and (min-width: 991px){
    display: none;
  }

  img {
    max-width: 25px;
  }
}

.user-nav {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: $white;
  background-color: $teal-600;

  i {
    color: $white !important;
  }
}

@media screen and (min-width: 991px) {
  .nav-mobile {
    display: none;
  }

  .navigation-deskt {
    .navbar-toggler {
      display: none;
    }

    img {
      max-width: 200px;
    }

    .nav {
      display: flex;
      margin-left: auto;
      align-items: center;

      a,
      i {
        color: $white;
      }

      .nav-link {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 400;
        font-size: 12px;
        text-transform: uppercase;
        border-radius: 3px;
        line-height: 20px;
        padding: 5px;

        i {
          display: none;
        }

        p {
          margin-bottom: 0;
          margin-left: 10px;
          text-transform: uppercase;
          font-size: 14px;
        }
      }

      .club-btn {
        background: $green-400;
        padding: 7px 20px;
        color: $white !important;
        margin-left: 20px;

        i {
          color: $white !important;
        }
      }
    }

    &.clubs, &.club {
      padding:  10px 0;
      background: $white;

      .nav-link {
        color: $black;

        i {
          color: $black;
        }
      }

      .logo-small {
        max-width: 50px;
      }

      .club-btn {
        display: none;
      }
    }
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }

    img {
      width: 20px;
    }

    .wrapper-search-button {
      margin-right: 10px;
    }
  }

  .filters {
    height: auto;
    width: 100%;
    position: relative;
    left: 0;
    padding: 10px 15px 0 15px;
    margin-top: 10px;
    border-top: 1px solid #D3D3D3;

    .card {
      padding: 0 15px;
    }

    .card-body {
      padding: 5px !important;
    }

    .close {
      display: none;
    }
  }

  .filters-bar {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .navbar-absolute.fixed-top {
    background: $white !important;
    position: fixed;
    z-index: 5;
  }

  .mobile-links {
    margin-left: auto;
  }
}
