.card-rezervare{

  .wrapper-btns {
    margin-top: auto;
  }

  .btn{
    height: 2.5rem;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .from{
      text-transform: none;
    }
  }
  .ora{
    margin-left: auto;
  }
  .durata{
    .price{
      margin-left: auto;
    }
  }
  strong{
    font-weight: bold;
  }
  &.green-bk{
    border:2px solid $green-400;
  }
}

@media screen and (min-width: 768px) {
  .card-rezervare{
    .btn{
      padding: 0 25px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .card-rezervare{
    .btn{
      padding: 0 15px;
    }
  }
}

@media screen and (min-width: 1500px) {
  .card-rezervare{
    .btn{
      padding: 0 25px;
    }
  }
}

