.react-datepicker__time-container--with-today-button{
  border:none!important;
  border-radius: 0!important;
  right:-85px!important;
}


.bmd-form-group{
  .react-datepicker-wrapper{
    min-width: 64%;
  }
}

.btn-save-block{
  margin: 0;
  background: $green-400;
  height: 38px;
  width:100%;
  font-size: 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    background: $green-400;
  }
}

.delperiod{
  &:hover{
    cursor: pointer;
  }
}

.react-datepicker__input-container{
  &:hover{
    input{
      cursor: pointer;
    }
  }
}