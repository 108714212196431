body{
  background: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  overflow-x: hidden;

  &.fixed {
    width: 100%;

    @media screen and (max-width: 991px){
      position: fixed;
      overflow: hidden;
      width: 100%;
    }
  }
}

#root {
  position: relative;
}

.logo {
  min-width: 120px;

  @media screen and (min-width: 768px){
    min-width: 200px;
  }
}

.navbar-toggler{
  padding-right: 0;
}

.spinner-container {
  min-height: 400px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.admin-users {
  .MuiDataGrid-footerContainer {
    display: none;
  }
}

.MuiDataGrid-footerContainer {
  p {
    margin: 0!important;
  }
}

.MuiDataGrid-cell {
  padding: 10px!important;
}

.MuiDataGrid-toolbarContainer {
  button {
    color: $green-400;
  }
}

.MuiDataGrid-panel {

  .MuiSwitch-switchBase.Mui-checked {
    .MuiSwitch-thumb {
      background: $green-400!important;
    }
  }

  .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
      background-color: $green-300!important;
  }

  .MuiButton-text {
    color: $green-400;
  }

  .MuiFormLabel-root.Mui-focused {
    color: $green-400;
  }

  .MuiInput-root.Mui-focused{
    &:after {
      border-bottom-color: $green-400!important;
    }
  }
}


.card{
  .card-header-teren{
    background-color: $teal-200!important;
    z-index: 1!important;
  }
}
i{
  user-select: none;
}

.switch-recurenta{
  margin: 0 2rem;
}

.btn-voucher {
  border: 1px solid;
  padding: 9px;
}

.rccs {
  margin: 0 40px 0 0!important;
  padding: 0 15px;

  @media screen and (max-width: 660px){
    padding: 0 15px;
    margin: 20px auto;
    width: 100%;
    display: contents;
  }
}

.wrapper-cards {
  width: 100%;

  @media screen and (min-width: 660px){
   width: auto;
  }
}


.cart-teren{
  padding: 0 15px;
}

.libere{
  color: $green-400;

}
.partialr{
  color: $orange-300;
}
.rezervat{
  color: $red-400;
}

.legenda{
  margin-top: 3rem;
  span{
    margin-right: 3rem;
    display: block;
    &:last-child{
      margin-right: 0;
    }
  }
}



.navbar-nav .nav-link{
  padding-left: 15px;
  padding-right: 15px;

  &:hover {
    cursor: pointer;
  }
}

.teren-card{
  display: flex;
  align-items: center;
  padding: 10px;
  .card-icon{
    margin: 10px 0!important;
    padding: 0!important;
  }
  .card-title{
    width:100%;
    text-align: center;
  }
}

.btn.btn-success{
  background-color: $green-custom;
  &:hover,&:focus,&:active{
    background-color: $green-custom;
  }
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  background-color: $green-custom;
}

.card-header-admin{
  background: #12803c!important;
  color: white!important;
  font-size: 16px;
}

.react-datepicker-wrapper{
  input{
    -moz-appearance: none;
    -webkit-appearance: none;
  }
}

.form-check .form-check-input:checked + .form-check-sign .check {
  background: $green-400;
}

.form-check{
  display: flex;
  align-items: center;
  .form-check-label{
    padding-left: 25px;
  }
}
.btn-zi{
  min-width: 70px;
}

.cerror{
  font-size: 13px;
  color: $red-600;
}

.card-header-image{
  min-height: 160px;
}

.op0{
  opacity: 0;
}

.fw-500{
  font-weight: 500;
}

.text-green-400 {
  color: #12803c;
}

.fw-600{
  font-weight: 600;
}

.t_status{
  p{
    padding: 10px 0;
    border-radius: 5px;
    &.success{
      background-color: $green-100;
      color:$black;
    }
    &.warning{
      background-color: $yellow-100;
      color: $black;
    }
    &.alert{
      background-color: $red-100;
      color: $black;
    }
  }
}

.tw-btn{
  max-width: 300px;
  h5{
    font-weight: 400;
    color: $white!important;
  }
}

.mh-5{
  min-height: 50px!important;
}

.c-raport{
  padding: 30px;
  margin-top: 0;
  height: 100%;
  .row-info{
    padding: 10px 0;
    border-bottom: 1px solid $border-color;
  }
}

.wrapper-applogos{
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width:100%;
  .app-img{
    max-width: 200px;
  }
}
.bb-green{
  border-bottom: 2px solid $green-400;
}

.landing{
  margin-top: 130px;
  h1{
    font-size: 30px;
    margin: 20px 0;
    line-height: 35px;
  }
}


@media screen and (max-width: 768px){
  .navbar .navbar-brand{
    margin-left: 0;
  }
  .card-header-image{
    min-height: 200px;
  }
}

@media screen and (min-width: 768px){
  .wrapper-applogos{
    flex-direction: row;
    width:100%;
  }
}

.wrapperloader{
  height: calc(100vh - 148px);
}

@media screen and (min-width: 992px){

  .card-header-image{
    min-height: 245px;
  }
  .btn-zi{
    min-width: 100px;
  }
  .close-map{
    display: none;
  }
  .landing{
    margin-top: 0;
    h1{
      font-size: 50px;
      margin: 20px 0;
      line-height: 60px;
    }
    .map{
      margin-top: 130px;
      height: calc(100vh - 180px);
    }
    .wrapper{
      margin-top: 182px;
    }
    .lp-head{
      position: fixed;
      width: 100%;
      height: 100px;
      top: 70px;
      z-index: 100;
      background-color: $grey-100;
      border-top: 2px solid $green-400;
    }
  }
}

.hidden {
  display: none!important;
}

button{
  &:focus{
    outline: none!important;
  }
}

.infinite-scroll-component {
  display: flex;
  flex-wrap: wrap;
  &__outerdiv{
    width:100%;
  }
}

.react-colorful{
  width: auto!important;
}

.color-picked input{
  width:100%;
  border: 1px solid $grey-400;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
}

.code-copy{
  display: flex;
  align-items: center;
  color:#12803c;
}

.copy-code{
  background-color: #c7eeb0;
  padding: 5px 10px;
  font-weight: 400;
  border-radius: 5px;
  margin-left: 10px;
  color:#000;
  &:hover{
    cursor: pointer;
  }
}

.loading-pannel-full-window {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, .4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-border{
  &::before {
    content: "";
    display: inline-block;
    width: 30%;
    height: 1px;
    position: relative;
    vertical-align: middle;
    background: linear-gradient(90deg, transparent, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4));
    right: 0.5em;
    margin-left: -50%;
  }

  &::after {
    content: "";
    display: inline-block;
    width: 30%;
    height: 1px;
    position: relative;
    vertical-align: middle;
    left: 0.5em;
    margin-right: -50%;
    background: linear-gradient(90deg, hsla(0, 0%, 46%, .4), hsla(0, 0%, 46%, .4), transparent);
  }
}

.card-rounded {
  margin: 0;
  border-radius: 10px;
  box-shadow: 0 0.25rem 0.875rem 0 rgba(38, 43, 67, 0.16);
}

@media screen and (min-width: 1200px) {
  .container{
    max-width: 1400px!important;
  }
}


.terms-content ul {
  @apply list-disc list-inside ml-6; /* or whatever suits your design */
}

.terms-content ol {
  @apply list-decimal list-inside ml-6;
}

.terms-content li {
  @apply mb-1; /* small margin between list items */
}

.terms-content h2 {
  @apply text-2xl font-bold mt-4 mb-2;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: transparent !important;
  box-shadow: none !important;
}
