@media screen and (max-width: 767px){
 .p-0-mobile {
   padding: 0 !important;
 }

  .card-admin{
    margin-bottom: 0;
    a{
      margin: 0;
    }
    .card-header{
      display: flex;
      align-items: center;
      .small-icon{
        padding: 0;
        margin: 10px 0;
      }
      .card-title{
        margin-left: auto!important;
        padding: 0!important;
        font-size: 18px!important;
      }
    }
  }
  .rezervari-btns{
    margin: 5px!important;
    padding-top: 0!important;
    border: none!important;
    .btn{
      display: inline-block;
      a{
        padding:10px;
        color: white;
      }
    }
  }
}

.card-admin{
  .card-footer{
    padding-top: 15px;
    min-height: 65px;
    margin-top: 20px;
    border-top: 1px solid rgba(0,0,0,.1);
    display: none;
    align-items: center;
    justify-content: center;
  }
  .btn{
    text-transform: capitalize;
  }
  .btn-success{
    display: flex;
    color:$white!important;
    align-items: center;
    justify-content: center;

    a{
      color:$white!important;
    }
  }
  .card-title{
    margin-left: auto!important;
    padding: 0!important;
    font-size: 20px!important;
  }
}

.rezervari-btns{
  justify-content: space-between!important;
  display: flex!important;
  .btn{
    display: inline-block;
    padding: 0;
    a{
      display: block;
      padding:10px;
      color: white;
      font-size: 13px;
    }
  }
}

.navigation-item .slick-track {
  min-width: 100%;
  transform: translate3d(0, 0, 0)!important;
}

@media screen and (min-width: 768px){
  .card-admin{
    .card-footer{
      display: flex!important;
    }
  }
}

@media screen and (min-width: 1200px){
  .rezervari-btns{
    .btn{
      a{
        padding:10px 35px;
        min-width: 100px;
        font-size: 15px;
      }
    }
  }
}
