.slick-track{
  display: flex!important;
}

    .inchis{
      background: $red-400;
      color: $white;
    }

    .liber4{
      background: $green-700;

    }

    .liber3{
      background: $green-500;
    }

    .liber2{
      background: $deep-orange-500;
    }
    .liber1{
      background: $deep-orange-700;
    }
    .liber0{
      background: $light-blue-500;
    }

    .head-time{
      border-radius: 0;
      z-index: 20;
    }

.tables-modal{
  .field-details{
    min-height: 90px;
  }
  .close{
    margin-left: auto;
    margin-right: 10px;
    margin-top: 10px;
  }
}

.modal-dialog{
  margin: 0 auto !important;
}

.alert{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  .alert_mes{
    padding: 50px 30px;
    border-radius: 5px;
    max-width: 50vw;
    background: white;
    text-align: center;
    position: relative;
    i{
      position: absolute;
      top:5px;
      right:5px;
      &:hover{
        cursor: pointer;
      }
    }
  }
}

@media screen and (min-width: 768px){
  .modal-dialog{
    width: 90vw;
    max-width: 100%!important;
  }
}

@media screen and (min-width: 992px){
  .sablon{
    .time{
      border-bottom: 1px solid $white;
    }
  }
}


@media screen and (min-width: 1280px){
  .modal-dialog{
    width: 75vw;
  }
}
