.react-tabs {
  &__tab-list {
    border: none !important;
    margin: 10px 10px 10px 10px !important;
    display: block;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  &__tab {
    border: 1px solid $green-400 !important;
    background: $green-400;
    color: $white;
    border-radius: 5px;
    margin: 0 10px 5px 10px;
    padding: 5px 25px !important;
    text-align: center;
    font-weight: bold;
    &--selected {
      color: $white !important;
      background: $green-400 !important;
      border-radius: 5px !important;
      border: 2px solid $black !important;
    }
    &.red {
      border: 1px solid $red-500 !important;
      background: $red-500!important;
      color: $black!important;
      font-weight: bold;
      &[activ~="inactiv"] {
        border: 1px solid $red-500 !important;
        color:$white !important;
      }
      &.react-tabs__tab--selected{
        border: 2px solid $black !important;
      }
    }
  }
}

.change-teren{
  background: $green-400;
  border-radius: 5px;
  .css-1uccc91-singleValue{
    color: white;
  }
}

.teren-sel{
  margin-bottom: 20px;
}

.field-details {
  padding-left: 5px;
  &.\31{
    border-bottom: 2px solid $red;
    background-image:none!important;
  }
  &.\32{
    border-bottom: 2px solid $blue;
    background-image:none!important;
  }
  &.\33{
    border-bottom: 2px solid $orange;
    background-image:none!important;
  }
  &.\34{
    border-bottom: 2px solid #FFF300;
    background-image:none!important;
  }
  &.\35{
    border-bottom: 2px solid $green-500;
    background-image:none!important;
  }
  &.\37{
    border-bottom: 2px solid $pink-500;
    background-image:none!important;
  }
  &.\38{
    border-bottom: 2px solid $cyan-500;
    background-image:none!important;
  }
}

.rez-colors{
  margin-top: -17px;
  margin-bottom: 15px;
  text-align: center;
  .btn{
    display: inline-block;
    text-align: center;
    line-height: 15px;
    font-size: 14px;
    padding: 5px 0;
    margin-right: 15px;
    min-width: 50px;
    &.active{
      border: 2px solid $black;
      padding: 8px 13px;
      transition-timing-function: cubic-bezier(0.1, 0.7, 1.0, 0.1);
    }
    &.r{
      background: #F44336;
    }
    &.a{
      background: #2196F3;
    }
    &.vi{
      background: #9C27B0;
    }
    &.p{
      background: #FF9800;
    }
    &.ve{
      background: #4CAF50;
    }
    &.ga{
      background: #FFF300;
      color: $black;
    }
    &.fucsia{
      background: $pink-500;
      color: $black;
    }
    &.cyan{
      background: $cyan-500;
      color: $black;
    }
  }
}



.element-modal{
  position: inherit!important;
}

.css-yk16xz-control{
  background: transparent;
}

.rezervat{
  .css-yk16xz-control,.css-5duvv3-control{
    background: transparent;
  }
  .color-s{
    color:$black;
  }
}
.element-rezervat{
  position: absolute;
  background: $white;
  z-index: 0;
  opacity: .2;
  height: 100%;
  width:100%;
  display: block;
  top:0;
  left:0;
  border-radius: 5px;
  &.\31{
    background: #F44336;
    color: $white;
    &::placeholder{
      color:$white;
    }
  }
  &.\32{
    background: #2196F3;
    color: $white;
    &::placeholder{
      color:$white;
    }
  }
  &.\33{
    background: #FF9800;
    color: $white;
    &::placeholder{
      color:$black;
    }
  }
  &.\34{
    background: #FFF300;
    color: $black;
    &::placeholder{
      color:$black;
    }
  }
  &.\35{
    background: #4CAF50;
    color: $white;
    &::placeholder{
      color:$white;
    }
  }
  &.\36{
    background: #9C27B0;
    color: $white;
    &::placeholder{
      color:$white;
    }
  }
}
[activ~="inactiv"] {
  position: relative!important;
  .element-rezervat{
    display: none;
  }
}


.css-26l3qy-menu{
  position: relative;
  z-index: 3!important;
}

@media screen and (min-width: 375px){
  .react-tabs{
    &__tab{
      padding: 5px 40px!important;
    }
  }
}

@media screen and (min-width: 992px){
  .react-tabs{
    &__tab{
      padding: 5px 20px!important;
    }
  }
}

@media screen and (min-width: 1280px){
  .teren-m{
    width:50%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px){
  .teren-sel{
    margin-bottom: -60px;
  }
}
