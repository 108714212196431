.table-prices{
  thead{
      th{
        padding: 1rem;
        min-width: 10rem;
        text-align: center;

      }
  }
  tbody{
    tr{
      th,td{
        padding:1rem;
        text-align: center;
      }
    }
  }
  .btn{
    margin: 0;
    display: flex;
    align-items: center;
  }
}




  @media screen and (max-width: 768px){

    .wrapper-table-fixed{
      .sablon {
        margin: auto;
        display: block;
        overflow: auto;
      }
      thead,
      tbody {
        display: inline-block;
      }


      thead {
        position: sticky;
        top: -2px;
        z-index: 20;
      }

      tbody {
        height: 90vh;
      }

      th {
        border: none;
      }
      td,
      th {
        min-width: 6rem;
        max-width: 6rem;
        word-wrap: break-word;
        &:first-child{
          min-width: 4rem;
        }
      }
      .fix-col {
        position: sticky;
        width: 5rem!important;
        left: -1px;
        top: auto;
        z-index: 10000000;
      }
      .head-time{
        z-index: 10000001;
      }

      td:not(.fix-col) {
        z-index: 0;
      }
    }
    }

  @media screen and (min-width: 768px){
      .table {
        overflow: hidden;
      }
      .time{
        border-bottom: 1px solid $white;
      }
     
    }
    .table-prices{
      thead{
        tr{
          th{
            padding: 1.2rem 1rem;
          }
        }
      }
      tbody{
        tr{
          th,td{
            padding: 1.2rem 1rem;
          }
        }
      }
    }