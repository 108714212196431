.element-modal{
  position: relative;
  .element-succes{
    position: absolute;
    z-index: 5;
    top:0;
    left: -3px;
    width: calc(100% + 3px);
    height: calc(100% + 10px);
    background: rgba(255,255,255,1);
    color:$green-400;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    i{
      margin-right: 10px;
    }
  }
}

.modalRezerva {
   width: 100%;
  padding: 30px 0 20px 0;
  .tables-modal{
    width: 100%;
  }
  .close{
    position: absolute;
    right:5px;
    top:5px;
    margin: 0;
    z-index: 40;
  }
  .rez-conf{
    padding-left: 10px;
    padding-right: 10px;
  }
  .wrapper-modal-c{
    padding: 0 15px 30px 15px;
  }

  .options {
    .form-group {
      width: 50%;
      display: inline-flex;
      align-items: center;

      @media screen and (max-width: 768px){
        width: 100%;
      }
      .form-check {
        margin-left: 2px!important;
      }
    }
  }


  .card-option {
    .form-check {
      .circle, .check {
        width: 20px;
        height: 20px;
      }

      .circle {
        &:before {
         display: none;
        }
      }
    }
  }

  .form-check-sign {
    &:before {
      display: none;
    }
  }

  .small {
    margin-bottom: 10px;
  }

  .rezerva-btns {
    .btn-success {
      &.disabled {
        background: $grey-300;
        color: $black;
        box-shadow: none;
      }
    }
  }

  .row-confirmation {
    border-top: 1px solid $border-color;
    padding: 10px 0;
    margin: 0!important;
    &:last-child {
      border-bottom: 1px solid $border-color;
    }
    >div {
      padding: 0;
    }
  }

  .wrapper-loader {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hidden {
    display: none;
  }

}
.modal-backdrop
{
  opacity:0.8 !important;
}
