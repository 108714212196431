.r-select {
  width: 100%;
  margin-left: 15px;
}

.text-center{
  .form-check{
    text-align: center;
    display: block;
    margin: 0 auto;
  }
}

@media screen and (max-height: 800px){
  .card-signup{
    margin-top: 30vh;
  }
}
