.rating{
    margin-bottom: 30px;
    .star-ratings{
        margin-right: 10px;
    }
    .star-container{
        height: 30px;
    }
    .rate-name{
        font-size: 16px;
    }
}
.review{
    margin-bottom: 30px;
    textarea{
        width:100%;
        min-height: 150px;
        padding: 10px;
        border:2px solid #dee2e6;
        border-radius: 5px;
        font-weight: 500;
    }
}

.title{
    margin-bottom: 30px;
    input{
        min-height: 40px;
        border-radius: 5px;
        box-shadow: none;
        border:2px solid #dee2e6;
        font-weight: 500;
        padding: 0 10px;
        line-height: 30px;
    }
}

.submit-review{
    font-weight: 600!important;
    font-size: 16px;
}

.wrapper-review{
    display:flex;
    flex-direction: column;
    margin-bottom: 30px;
    width: 100%;
    position: relative;
    padding: 0 20px;
    margin-top: 0;
    .review-logo{
        width:200px;
        min-width: 200px;
        position:relative;
        margin-bottom: 20px;
        .logo{
            max-width: 100px;
            width:100%;
            height: auto;
            display: block;
        }
    }

}

.review-avatar{
    display: flex;
    width: 100px;
    height: 100px;
    min-width: 100px;
    background-color: green;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin:20px 0;
    overflow: hidden;
    align-items: center;
    p{
        font-size: 30px;
        font-weight: 600;
        color:white;
        margin: 0;
    }
    img{
        width:100%;
    }
}

.review-description{
    font-weight: 500;
}

.review-content{
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.react-tabs__tab-panel{
    .col-12{
        &:last-child{
            .card-review-club{
                border-bottom: none;
            }
        }
    }
}

.wrapper-no-reviews{
    background-color: #f0f0f0;
    padding: 10px 20px;
    border-radius: 5px;
    h3{
        font-size: 20px;
    }
    .add-review{
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 150px;
    }
    .rate-name{
        margin-left: 10px!important;
    }
}

.card-review-club{
    border-bottom: 1px solid #dee2e6;

    .date{
        text-transform: capitalize;
    }
    .userimage{
        border-radius: 50%;
        width: 60px;
        height: 60px;
        background-color: #dee2e6;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        color:white;
    }
    .add-comment{
        &:hover{
            cursor: pointer;
        }
        span{
            margin-left: 5px;
        }
    }
    textarea{
        width:100%;
        border-radius: 5px;
        border: 1px solid #dee2e6;
        padding: 10px;
    }
    .wrapper-add-comment{
        .btn-outline{
            border-color: #12803c;
            color:#12803c;
            height: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
        }
    }
    .avatar-add-comment{
        display:flex;
        align-items: center;
        margin-bottom: 10px;
        .avatar{
            height: 40px;
            width: 40px;
            background: #dee2e6;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
        }
        p{
            color:#999;
        }

    }
    .wrapper-comments{
        margin-bottom: 30px;
        padding-top: 10px;

        .comment-card{
            background-color: #f0f0f0;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 5px;
            position:relative;
            .date{
                font-size: 13px;
                font-weight: 600;
                margin-top: -5px!important;
            }
            .text-green{
                color:#12803c;
            }
            textarea{
                min-height: 100px;
            }
        }
        .btn-comment{
            font-size: 12px;
            font-weight: 600;
            color: #12803c;
            &:hover{
                cursor:pointer;
              }
        }
    }
    .verified{
        font-size: 13px;
    }

}

.review-actions{
    p{
        &:hover{
            cursor: pointer;
        }
    }
}

.admin-profile {
    .react-tabs__tab-list{
        display: flex;
        margin: 0 -5px 30px -5px !important;
        justify-content: center;
    }
    .react-tabs{
        &__tab{
            display:inline-flex;
            flex-grow: 1;
            margin: 5px;
            justify-content: center;
            background: transparent;
            color:#12803c;
            align-items: center;
            padding: 5px!important;
            max-width: 150px;
        }
        &__tab--selected{
            background: #12803c;
            color:white;
            border: none!important;
        }
    }
}

.club-desc{
    .react-tabs__tab-list{
        display: flex;
        margin: 0 -5px 20px -5px!important;
    }
    .react-tabs{
        &__tab{
            display:inline-flex;
            flex-grow: 1;
            margin: 5px;
            justify-content: center;
            background: transparent;
            color:#12803c;
            align-items: center;
            padding: 5px!important;
        }
        &__tab--selected{
            background: #12803c;
            color:white;
            border: none!important;
        }
    }

    .value-rating{
        font-size: 25px;
        display: inline-block;
        margin: -9px 0 0 15px;
    }
}

.rating-wrapper{
    .rating-number-club{
        font-size: 60px;
        font-weight: 500;
        letter-spacing: 1.5px;
        color:#666;
        line-height: 65px;
    }
    .rating-number{
        font-weight: 500;
        color: #999;
        letter-spacing: 1px;
    }
}

.star-container{
    display: inline-flex!important;
    align-items: center;
}

.edited{
    font-style: italic;
    color: $grey-500;
    font-size: 12px;
}

.pagination{
    .MuiPaginationItem-root{
        &:focus{
            border:none!important;
        }
    }
    .Mui-selected{
        background-color: #12803c!important;
        color:white;

    }
}

@media screen and (min-width:768px) {
    .club-ratings{
        .bb-md-right{
            border-bottom: none!important;
            border-right: 1px solid #dee2e6;
            padding-left: 30px;
            display: flex;
            align-items: center;
            flex-direction: column;
            .rating-number{
                text-align: center;
            }
        }
        .add-review{
            padding-left: 30px;
        }
    }

}

@media screen and (min-width:992px) {
    .wrapper-review{
        flex-direction: row;
        padding: 20px;
        .review-avatar{
            margin-right: 20px;
            margin-bottom: 0;
            margin-top: 0;
        }
        .review-logo{
            margin-right: 20px;
        }
    }
    .wrapper-no-reviews{
        h3{
            font-size: 25px;
        }
    }
}
