@use "sass:math";



@import "material-dashboard/variables";
@import "material-dashboard/mixins";
@import "material-dashboard/core-bootstrap";

 // Core Components
 @import "material-dashboard/buttons";
 @import "material-dashboard/checkboxes";
 @import "material-dashboard/radios";
 @import "material-dashboard/forms";
 @import "material-dashboard/input-group";
 @import "material-dashboard/images";
 @import "material-dashboard/navbar";
 @import "material-dashboard/alerts";
 @import "material-dashboard/headers";
 @import "material-dashboard/type";
 @import "material-dashboard/tabs";
 @import "material-dashboard/tooltip";
 @import "material-dashboard/popover";
 @import "material-dashboard/dropdown";
 @import "material-dashboard/togglebutton";
 @import "material-dashboard/ripples";
 @import "material-dashboard/footers";
 @import "material-dashboard/sidebar-and-main-panel";
 @import "material-dashboard/fixed-plugin";
 @import "material-dashboard/tables";
 @import "material-dashboard/misc";
 @import "material-dashboard/social-buttons";

@import "material-dashboard/cards";
@import "material-dashboard/cards/card-stats";
@import "material-dashboard/cards/card-profile";
@import "material-dashboard/cards/card-plain";
@import "material-kit/headers";
@import "material-kit/forms";
@import "material-kit/custom-forms";
@import "material-kit/sections/headers-extend";
@import "material-kit/sections/features";
@import "material-kit/mixins/layout";
@import "material-kit/modal";
@import "material-kit/radios";
@import "material-kit/forms";
@import "material-kit/mixins/forms";
@import "material-kit/headers";
@import "material-kit/responsive";
@import "material-kit/plugins/plugin-bootstrap-select";
@import "material-kit/plugins/plugin-bootstrap-select";
@import "material-kit/plugins/plugin-datetime-picker";
@import "material-kit/variables/menu";
@import "material-kit/cards/card-login";


//plugin scss
@import "material-dashboard/plugins/animate";
@import "material-dashboard/plugins/chartist";
@import "material-dashboard/plugins/perfect-scrollbar";

@import "material-dashboard/responsive";
@import "custom-components/utilities";
@import "custom-components/admin-club";
@import "custom-components/data-table";
@import "custom-components/sablon";
@import "custom-components/table-fixed";
@import "custom-components/navbar";
@import "custom-components/panel-home";
@import "custom-components/teren-tabs";
@import "custom-components/club_modal";
@import "custom-components/clubs-page";
@import "custom-components/club-card";
@import "custom-components/static-text";
@import "custom-components/footer";
@import "custom-components/card-rezervare";
@import "custom-components/sidebar";
@import "custom-components/clubpage";
@import "custom-components/card-rezervare-admin";
@import "custom-components/modal-anulare-rez";
@import "custom-components/spinner";
@import "custom-components/custom-date";
@import "custom-components/register-form";
@import "custom-components/time-picker";
@import "custom-components/modal-rezerva";
@import "custom-components/review";
@import "custom-components/app_section";
@import "custom-components/three_steps_section";
@import "custom-components/club_elements_slider";
@import "custom-components/search_form";
@import "custom-components/media_section";

@tailwind base;
@tailwind components;
@tailwind utilities;
