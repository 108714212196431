.modal-anulare {
  .modal-content {
    width: 100%;
    max-width: 500px;
    margin:0 auto;
    margin-top: 20vh;
    .modal-confirm-anulare{
      text-align: center;
      padding: 10px;
      h4{
        padding: 10px;
      }
      .btn{
        max-width: 250px;
        margin: 20px;
        &.btn-error{
          background: $red;
        }
      }
    }
  }
}

.modal-voucher {
  background: rgba(0, 0, 0, .8);

  input {
    border: 2px solid $gray-400;
    text-align: left;
    border-radius: 5px;
    padding: 10px;
  }

  .modal-body {
    max-height: 300px;
    margin-top: 20px;
  }

  .close {
    z-index: 99999999;
    &:hover {
      cursor: pointer;
      position: relative;
    }
  }

  .err {
    color: $red-300;
  }

}