.headClub{
  min-height: 450px;
  color: $white;
  display: flex;
  align-items: flex-end;
  background-size: cover!important;
  background-position: center center!important;
  position: relative;
  background-blend-mode: multiply;
  h1{
    font-size: 30px;
    line-height: 35px;
    font-weight: 400;
  }
  &:after{
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    background: rgba(0,0,0,.2);
  }
}
.cardli-ico{
  width: 18px;
  margin-right: 5px;
  vertical-align: middle;
}
.sidebar-club{
  .leaflet-container{
    min-height: 300px;
    width:100%;
  }

  .card-wHead{
    h3{
      font-size: 18px;
      font-weight: 600;
      margin-top: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid $border-color;
      margin-bottom: 0;
    }
  }
  .amenities{
    margin-top: 20px;
    li{
      display: inline-flex;
      background: #f0f0f0;
      font-size: 14px;
      margin-right: 10px;
      border-radius: 40px;
      padding: 5px 20px;
      margin-bottom: 10px;
    }
  }

  .program{
    list-style-type: none;
    margin: 0;
    li{
      padding: 0;
      border-bottom: 1px solid $border-color;
      min-height: 35px;
      display: flex;
      align-items: center;
      font-weight: 400;
      span{
        &:last-child{
          color: $black;
          margin-left: auto;
        }
      }
    }

  }
}
.club-desc{
  h3{
    font-size: 26px;
    font-weight: 600;
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    text-align: center;
    margin-bottom: 0;
  }
  .desc{
    padding: 20px 0;
    font-size: 16px;
  }
  .pics{
    padding: 20px 0;
    img{
      max-width: 100%;
      display: none;
      width:100%;
      &:first-of-type{
        display: block;
      }
    }
    .gallery-wrapper{
      position: relative;
      cursor: pointer;
      max-height: 500px;
      overflow: hidden;
      .openGallery{
        padding: 8px 0;
        background-color: $green-400;
        position: absolute;
        bottom:0;
        left:0;
        width:100%;
        color: $white;
        margin: 0;
        text-align: center;
        font-weight: 600;
      }
      .pswp__img{
        display: block;
      }
    }
  }
  .react-tabs__tab {
    font-weight: 400!important;
  }
}



.aws-btn {

  --slider-height-percentage: 60%;
  --slider-transition-duration: 700ms;
  --organic-arrow-thickness: 7px;
  --organic-arrow-border-radius: 30px;
  --organic-arrow-height: 53px;
  --organic-arrow-color: #ffffff;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #2d5182;
  --control-bullet-active-color: #26456f;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
}

.awssld__controls__arrow-left:before {background: $white!important;opacity: 1!important;}
.awssld__controls__arrow-left:after {background: $white!important;}
.awssld__controls__arrow-right:before {background: $white!important;}
.awssld__controls__arrow-right:after {background: $white!important;}

.awssld__bullets button{
  width: 10px!important;
  height: 10px!important;
  background: $green-400!important;
}

.wrapper-schedule{
  overflow: scroll;
  .row-teren{
    display: flex;
    .cellHour{
      min-height: 40px;
      min-width: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      flex-grow: 1;
      flex-basis: 0;
      font-size: 12px;
      span{
        flex-grow: 1;
        height: 100%;
        display: inline-block;
        &.ocupat{
          background: $red-400;
          &:last-child{
            margin-right: -1px;
          }
        }
        &.liber{
          background: $green-200;
          &:hover{
            background: $green-300;
          }
        }
        &.past{
          background: $border-color;
          border: none;
        }
      }
    }
    .name{
      min-width: 150px;
      display: flex;
      align-items: center;
      font-weight: 600;
      text-align: center;
      justify-content: center;
      font-size: 14px;
      &:hover{
        background: $white;
      }
    }
    &:first-child{
      .cellHour{
        border-top: 1px solid $border-color;
      }
    }
    &.head{
      color: #999;
      font-weight: 400;
    }
  }
  .head{
    .cellHour{
      &:hover{
        background: $white;
      }
    }
  }
}

.wrapper-availability{
  display: flex;
  align-items: center;
  flex-direction: column;
}

.clubAvailableHours{
  display: flex;
  width: 100%;
  flex-direction: column;
  .listHours{
    display: flex;
    width: calc(100% + 15px);
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .wrapper-btn-book{
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    flex-grow: 1;
    min-width: 100px;
    p{
      margin:0 5px;
    }
    &:first-child{
      margin-left: 0;
    }
  }
  .btn-book{
    font-size: 15px;
    padding: 0;
    min-height: 38px;
    display: flex;
    min-width: 50px;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $green-400;
    color:$white;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
    .from{
      font-size: 10px;
      text-transform: none;
      display: block;
    }
  }
  .navailable{
    margin: 0;
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(47,51,51,.03);
    border-radius: 5px;
    color:$red-600;
    text-align: center;
    .loading{
      height: 20px;
      display: flex;
      align-items: center;
      img{
        max-height: 20px;
        margin-right: 5px;
      }
    }
    &.green{
      color: $green-400;
    }
  }
}

.swipe-an{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 40px;
  img{
    margin-left: 10px;
    max-width: 20px;
  }
}
.daypicker{
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .custom-input{
    height: 100%;
    width: 100%;
    margin: 0;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    padding-left: 10px;
    margin:0;
    &:hover{
      cursor: pointer;
    }
  }
  .icon--chevron-down{
    color: $green-400;
    height: 10px;
    transform: rotate(90deg);
    margin-top: -3px;
    position: relative;
    margin-left: auto;
    margin-right: 10px;
  }
  .react-datepicker-wrapper input{
    border: none;
    border-radius: 0;
    text-align: center;
    padding: 0;
  }
  .react-datepicker-popper{
    transform: translate3d(0px,29px,0px)!important;
    z-index: 1000;
  }
  svg{
    &:hover{
      cursor: pointer;
    }
  }
}

.legend{
  display:flex;
  justify-content: flex-end;
  padding:10px 0 20px 0;
  p{
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 0;
    .bullet{
      display: block;
      width: 12px;
      height: 12px;
      border: 1px solid $border-color;
      margin-right: 10px;
      border-radius: 50%;
      background: $green-200;
      &.ocupat{
        background: $red-400;
      }
      &.indisponibil{
        background: $border-color;
      }
    }
  }
}

.prices-front{
  .table-prices{
    table{
      tbody{
        height: auto!important;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 991px){
  .c-club-page {
    max-width: 100%;
  }
}



@media screen and (min-width: 768px){
  .headClub{
    h1{
      font-size: 50px;
      line-height: 60px;
    }
  }

}

.wrapper-availability{
    flex-direction: column;
    align-items: flex-start;
    .daypicker{
      max-width: 100%;
      margin-bottom: 20px;
      position: relative;
      .custom-input{
        height: 50px;
        border: 2px solid $grey-200;
        border-radius: 8px;
      }
      .react-datepicker-popper{
        position: absolute;
        margin-top: 21px;

      }
    }
    .navailable{
      margin-bottom: 10px;
    }
  }

.element-description{
  display: flex;
  flex-direction: column;
  padding: 20px;
  .image{
    margin-bottom: 30px;
    img{
      max-width: 100%;
    }
  }
}

  .title-available-hours {
    display: flex;
    margin: 20px 0;
    @media screen and (min-width: 768px){
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    p{
      display: block;
      margin: 0 20px;
    }

    &:before {
      content: "";
      flex: 1 1;
      border-top: 1px solid #d3d2d3;
      margin: auto;
    }

    &:after {
      content: "";
      flex: 1 1;
      border-top: 1px solid #d3d2d3;
      margin: auto;
    }
  }



@media screen and (min-width: 768px){
  .element-description{
    flex-direction: row;
    .image{
      margin-bottom: 30px;
      padding-right: 20px;
      max-width: 50%;
    }
  }
}

@media screen and (min-width: 992px){
  .headClub{
    min-height: 500px;
    h1{
      font-size: 60px;
      line-height: 70px;
    }
    p{
      font-size: 22px;
      line-height: 40px;
    }
  }
  .wrapper-schedule{
    overflow: hidden;
    .row-teren{
      .cellHour{
        min-width:15px;
      }
      .name{
        min-width: 150px;
      }
    }
  }
  .awssld__bullets {
    bottom: 20px!important;
    button{
      width: 10px!important;
      height: 10px!important;
      background: $white!important;
    }
  }
  .orar{
    margin-top: 10px;
  }

  .clubAvailableHours{
    .btn-book{
      min-width: 90px;
    }
  }

}

.date-carousel-container {
  margin-top: 40px;

  .carousel {
    display: flex;

    .arrow {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding-top: 10px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .slider-wrapper.axis-horizontal {
    .slider {
      .slide {
        .week-days {
          display: flex;
          justify-content: space-evenly;

          .day {
            font-weight: 400;
            &:hover {
              cursor: pointer;
            }
          }

          .current-date {
            color: #12803c;
            font-weight: 600;
          }

          .disabled {
            color: #d8d8d8;

            &:hover {
              cursor: not-allowed;
              pointer-events: all !important;
            }
          }
        }
      }
    }
  }
}
