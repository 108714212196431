.card-rezervare{
  margin-top: 0;
  border-radius: 10px;
  box-shadow: 0 0.25rem 0.875rem 0 rgba(38, 43, 67, 0.16);

  .wrapper-rez-infos{
    .left{
      padding: 20px 10px;
      text-align: center;
      font-weight: 400;
      h4{
        font-weight: 500;
      }
      p{
        margin:20px 0 0;
        font-size: 20px;
      }
      svg{
        width: 90%!important;
        display: block;
        margin: 0 auto;
        max-width: 250px;
      }
    }
    .right{
      padding: 20px 10px;
      h4{
        font-weight: 500;
        text-align: center;
      }
      ul{
        list-style-type: none;
        padding: 0;
        li{
          display: flex;
          padding: 5px 0;
          border-bottom: 1px solid $border-color;
          font-size: 14px;
          span{
            margin-left: auto;
            font-weight: 500;
            text-align: right;
          }
          &.danger{
            color: $red;
            font-weight: 500;
          }
        }
      }
      h5{
        font-weight: 500;
        text-align: center;
      }
    }
    .placeholder{
      min-height: 200px;
    }
  }
  .wrapper-btns{
    padding-bottom: 20px;
    .btn{
      margin: 0 10px;
      justify-content: center;
    }
    .waze{
      background: #00a4eb;
      color: $white;
    }
    .btn-success{
      color:$white;
    }
  }
  .btn-success{
    color:$white!important;
  }
  .expirat{
    color: $white;
    margin: 0 auto;
    display: block;
    margin-bottom: 4rem;
  }
  .card-band-header{
    width: 100%;
    text-align: right;
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    color:$black;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;

    .success, .expired, .pending {
      color: #fff;
      padding: 5px 15px;
      border-radius: 8px;
      display: inline-flex;
    }

    .success{
      background: $green-400;
    }
    .expired {
        background: #651716;
    }
    .pending{
      background: #E95705;
    }

    .sport {
      color: #651716;
      font-size: 16px;
    }
  }
}

.card-cards{
  .card-details{
    display:flex;
    align-items: center;
    padding: 30px 15px;
    h4{
      font-weight: 500;
      margin:0;
    }
    p{
      margin: 0;
    }
    img{
      max-width: 100px;
      margin-right: 20px;
    }
  }
  .card-footer{
    margin-top: 0;
    align-items: flex-end;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 30px;
    margin-bottom: 0;
    .btn{
      font-size: 16px;
    }
  }
}

.row-voucher {
  border-bottom: 1px solid $border-color;
}

@media screen and (min-width: 1200px){
  .card-rezervare{
    .wrapper-rez-infos{
      .left{
        svg{
          max-width: 200px;
        }
      }
    }
  }
}
